<template>
  <div class="empower">
    <!-- 项目弹窗 -->
    <van-popup
      v-model="$store.state.empowerShow"
      closeable
      position="bottom"
      :style="{ minHeight: '50%' }"
      z-index="99999"
      round
    >
      <div class="empowerPopup">
        <img src="../assets/img/logo.png" alt="" class="logoImg" />
        <span class="title-text"
          >蓝瑟，提供标准、正规绿色、全新的专业服务</span
        >
        <div class="confirmBtn" @click="clickConfirm">
          <svg-icon icon-class="wx" class="wxIcon" />微信快捷登录
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    clickConfirm() {
      let appid = "wx29a07078b3670b13"; //公众平台appid
      let local = encodeURIComponent(window.location.href);
      // console.log(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=wecedirect`);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=wecedirect`;
    }
  }
};
</script>

<style lang="less" scoped>
.empower {
  .empowerPopup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logoImg {
    width: 3rem;
    margin-top: 1rem;
  }
  .title-text {
    font-size: 0.4rem;
    margin: 0.5rem 0 2rem;
    font-weight: bold;
  }
  .confirmBtn {
    width: 80%;
    padding: 0.3rem 0;
    font-size: 0.5rem;
    border-radius: 0.8rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e82b2f;
    .wxIcon {
      font-size: 0.8rem;
      margin-right: 0.2rem;
    }
  }
}
</style>
